





































































import {Component, Prop, Vue} from 'vue-property-decorator'
import {InvoiceData, InvoiceSummaryRow, InvoiceStatus} from '~/components/invoice/invoice-data'
import {createRequest} from '~/utils/network-request'
import {numberWithComma, tenToDot} from '~/utils/misc'

@Component({})
export default class InvoiceSummary extends Vue {
    @Prop({default: () => new InvoiceData()})
    data: InvoiceData
    InvoiceStatus = InvoiceStatus

    get summary() {
        const s = this.data.summary.courses.slice()
        s.forEach(r=>r.jump = true)
        if (this.data.add_on.plus > 0) {
            const r = new InvoiceSummaryRow()
            r.course_id = 'Add-on'
            r.title = 'Add'

            r.instructor_profit = this.data.add_on.plus
            s.push(r)
        }
        if (this.data.add_on.minus > 0) {
            const r = new InvoiceSummaryRow()
            r.course_id = 'Add-on'
            r.title = 'Minus'

            r.instructor_profit = this.data.add_on.minus * -1
            s.push(r)
        }
        return s
    }

    sSummary() {
        const t = this.data.summary.total
        return ['', 'Total', t.paid_count, this.dollar(t.revenue), this.dollar(t.cost), this.dollar(t.instructor_profit)]
    }

    dollar(raw: number | string) {
        return (typeof raw === 'number') ? '$' + numberWithComma(tenToDot(raw)) : raw
    }

    profit(raw: number) {
        const amount = this.dollar(raw);
        return (raw < 0? '-' : '') + amount
    }

    get aAdd() {
        return this.data.add_on.plus / 10
    }

    set aAdd(v) {
        this.data.add_on.plus = v * 10
    }

    get aMinus() {
        return this.data.add_on.minus / 10
    }

    set aMinus(v) {
        this.data.add_on.minus = v * 10
    }

    async addOnChanged() {
        const res = await createRequest(`/invoice/${this.data._id}/add-on`, 'post', {}, {
            add_on: this.data.add_on
        }).send()
        this.$emit('update:data', res.data.data)
        this.$message.success('Updated')
    }
}
