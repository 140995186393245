export enum InvoiceStatus {
    Preview,
    Pending,
    Approved,
    Paid
}

export enum InvoiceCostType {
    NUM_OF_STUDENTS,
    ONE
}

export class InvoiceCost {
    title: string = ''
    unit_cost: number = 0
    total: number = 0
    type: InvoiceCostType = InvoiceCostType.ONE
}

export class InvoiceRevenue {
    splitting: number = 0.5
    coupon_title: string = ''

    price: number = 0
    paid_count: number = 0
    total: number = 0

    average?: number
}

export class InvoiceCourse {
    course_id: string
    title: string

    paid_count: number = 0

    costs: InvoiceCost[] = []
    revenue: InvoiceRevenue[] = []

    constructor(courseId: string = '', title: string = '') {
        this.course_id = courseId
        this.title = title
    }
}

export class InvoiceSummaryRow {
    course_id?: string
    title?: string

    paid_count: number | 'N/A' = 'N/A'
    revenue: number | 'N/A' = 'N/A'
    cost: number | 'N/A' = 'N/A'
    instructor_profit: number = 0

    jump?: boolean
}

export class InvoiceSummary {
    courses: InvoiceSummaryRow[] = []
    total: InvoiceSummaryRow = new InvoiceSummaryRow()
}

export class InvoiceAddOn {
    plus: number = 0;
    minus: number = 0;
}

export class InvoiceData {
    _id: string;

    instructor: number;
    member_id: string;
    year: number;
    month: number;
    created: number;

    courses: InvoiceCourse[] = [];
    add_on: InvoiceAddOn = new InvoiceAddOn();
    summary: InvoiceSummary = new InvoiceSummary();

    status: InvoiceStatus = InvoiceStatus.Pending;

    old?: boolean;
    display_name?: string;
}
