var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"h5 mb-3"},[_vm._v("Invoice Summary")]),_c('el-table',{staticClass:"border",staticStyle:{"border-radius":"4px"},attrs:{"data":_vm.summary,"show-summary":true,"summary-method":_vm.sSummary}},[_c('el-table-column',{attrs:{"label":"Course Code","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.jump)?_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$emit('jump', row.course_id)}}},[_vm._v(" "+_vm._s(row.course_id)+" ")]):_c('div',[_vm._v(_vm._s(row.course_id))])]}}])}),_c('el-table-column',{attrs:{"label":"Course Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.jump)?_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$emit('jump', row.course_id)}}},[_vm._v(" "+_vm._s(row.title)+" ")]):_c('div',[_vm._v(_vm._s(row.title))])]}}])}),_c('el-table-column',{attrs:{"label":"Paid Students","prop":"paid_count","align":"right","width":"120"}}),_c('el-table-column',{attrs:{"label":"Gross Revenue","align":"right","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.dollar(row.revenue))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Cost","align":"right","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.dollar(row.cost))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Tutor's Profit","align":"right","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.profit(row.instructor_profit))+" ")]}}])})],1),_c('div',{staticClass:"mt-5 mb-2 h6"},[_vm._v("Add-on")]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-2"},[_c('div',{staticClass:"p2"},[_vm._v("Add")]),_c('div',[_c('el-input',{attrs:{"size":"small","disabled":_vm.data.status > _vm.InvoiceStatus.Pending,"prefix-icon":"fas fa-dollar-sign"},on:{"change":_vm.addOnChanged},model:{value:(_vm.aAdd),callback:function ($$v) {_vm.aAdd=$$v},expression:"aAdd"}})],1)]),_c('div',[_c('div',{staticClass:"p2"},[_vm._v("Minus")]),_c('div',[_c('el-input',{attrs:{"size":"small","disabled":_vm.data.status > _vm.InvoiceStatus.Pending,"prefix-icon":"fas fa-dollar-sign"},on:{"change":_vm.addOnChanged},model:{value:(_vm.aMinus),callback:function ($$v) {_vm.aMinus=$$v},expression:"aMinus"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }