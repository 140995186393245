




import {Component, Prop, Vue} from 'vue-property-decorator'
import {InvoiceStatus} from './invoice-data'

@Component({components: {}})
export default class StatusDot extends Vue {
    @Prop()
    status: InvoiceStatus

    sColor = {
        [InvoiceStatus.Preview]: '#eeeeee',
        [InvoiceStatus.Pending]: '#ffc232',
        [InvoiceStatus.Approved]: '#0095ff',
        [InvoiceStatus.Paid]: '#4dbbb6',
    }
}
