var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"h5 mb-3"},[_vm._v("Course Summary")]),_c('div',{staticClass:"d-flex w-100 border-bottom mb-3",staticStyle:{"overflow":"auto"}},_vm._l((_vm.cIds),function(cid){return _c('a',{staticClass:"tab",class:{active: _vm.selectedCid === cid},staticStyle:{"padding":"16px"},attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$emit('update:selectedCid', cid)}}},[_vm._v(" "+_vm._s(cid)+" "),_c('div',{staticClass:"d-none b-bar",class:{'d-block': _vm.selectedCid === cid}})])}),0),_c('div',{staticClass:"h6"},[_vm._v("Gross Revenue")]),_c('el-table',{staticClass:"border",staticStyle:{"border-radius":"4px"},attrs:{"data":_vm.course.revenue,"show-summary":true,"summary-method":_vm.rSummary}},[_c('el-table-column',{attrs:{"label":"Price","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.dollar(row.price))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Coupon"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.coupon_title || 'N/A')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Tutor splitting","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.splitting * 100 + '%')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Paid Students","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.paid_count)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Total amount","align":"right","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.dollar(row.total))+" ")]}}])})],1),_c('div',{staticClass:"d-flex mt-4"},[_c('div',{staticClass:"h6 mr-2"},[_vm._v("Cost")]),(_vm.data.status <= _vm.InvoiceStatus.Pending)?_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.addCostItem}},[_c('i',{staticClass:"el-icon-circle-plus-outline"}),_vm._v(" Add Item ")]):_vm._e()]),_c('el-table',{staticClass:"border",staticStyle:{"border-radius":"4px"},attrs:{"data":_vm.course.costs,"show-summary":true,"summary-method":_vm.cSummary}},[_c('el-table-column',{attrs:{"label":"Items","prop":"title"}}),_c('el-table-column',{attrs:{"label":"Unit cost","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.dollar(row.unit_cost))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Unit","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.total / row.unit_cost)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Total Amount","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.dollar(row.total))+" ")]}}])}),(_vm.data.status <= _vm.InvoiceStatus.Pending)?_c('el-table-column',{attrs:{"label":"Actions","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticClass:"mr-3",attrs:{"type":"text","icon":"el-icon-edit","disabled":_vm.disableAction(row)},on:{"click":function($event){return _vm.editCostItem(row)}}}),_c('el-button',{class:{red: !_vm.disableAction(row)},attrs:{"type":"text","icon":"el-icon-delete","disabled":_vm.disableAction(row)},on:{"click":function($event){return _vm.removeCostItem(row)}}})]}}],null,false,1272665691)}):_vm._e()],1),_c('cost-item-dialog',{attrs:{"visible":_vm.costDialogVisible,"create":_vm.createCostItem,"item":_vm.editingCost,"paidCount":_vm.course.paid_count},on:{"update:visible":function($event){_vm.costDialogVisible=$event},"submit":_vm.submitCost}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }