











































import {Component, Prop, Vue} from 'vue-property-decorator'
import {InvoiceCost, InvoiceCostType} from './invoice-data'
import {numberWithComma, tenToDot} from '~/utils/misc'
import {createRequest} from '~/utils/network-request'

@Component({
    components: {}
})
export default class CostItemDialog extends Vue {
    @Prop({
        default: () => {
            return false
        }
    })
    visible!: boolean

    @Prop({default: false})
    create: boolean
    @Prop({default: 0})
    paidCount: number

    @Prop({
        default: () => {
            return new InvoiceCost()
        }
    })
    item: InvoiceCost

    InvoiceCostType = InvoiceCostType

    itemOpsRaw: {title: string, add?: boolean}[] = []
    itemOps: {title: string, add?: boolean}[] = []

    get unitCost() {
        return this.item.unit_cost / 10
    }

    set unitCost(v) {
        this.item.unit_cost = v * 10
    }

    get title() {
        return this.create ? 'Add item' : 'Edit item'
    }

    get total() {
        return this.item.unit_cost * ((this.item.type === InvoiceCostType.ONE)? 1 : this.paidCount)
    }

    dollar(raw: number | string) {
        return (typeof raw === 'number') ? '$' + numberWithComma(tenToDot(raw)) : raw
    }

    async created() {
        const res = await createRequest('/invoice-cost-items').send()
        this.itemOpsRaw = res.data.items.map(title=>{return {title}})
        this.itemOps = this.itemOpsRaw
    }

    itemInput(v) {
        if(!v)
            this.itemOps = this.itemOpsRaw
        else {
            this.itemOps = this.itemOpsRaw.filter(i=>i.title.includes(v))
            if(!this.itemOps.find(i=>i.title === v))
                this.itemOps.push({title: v, add: true})
        }
    }

    get canSubmit() {
        return this.item.title && this.item.unit_cost
    }

    submit() {
        this.item.total = this.total
        this.$emit('submit')
        this.dialogVisible = false
    }

    set dialogVisible(v) {
        this.$emit('update:visible', v)
    }

    get dialogVisible() {
        return this.visible
    }
}
