















import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import {InvoiceStatus} from '~/components/invoice/invoice-data'

@Component({
    components: {}
})
export default class InvoiceStatusSelect extends Vue {
    @Prop({default: InvoiceStatus.Pending})
    value!: number

    @Prop({default: ''})
    size: string

    @Prop({default: 'Status'})
    placeholder

    @Prop({default: false})
    clearable: boolean

    InvoiceStatus = InvoiceStatus

    created() {}

    @Emit()
    input(value: InvoiceStatus) {
        //
    }

    get localValue(): InvoiceStatus {
        return this.value
    }

    set localValue(value: InvoiceStatus) {
        this.input(value)
    }

    get selectClass() {
        return {
            [InvoiceStatus.Pending]: 'select-pending',
            [InvoiceStatus.Approved]: 'select-approved',
            [InvoiceStatus.Paid]: 'select-paid',
        }[this.value]
    }
}
