





































































import moment from 'moment'
import {Component, Vue} from 'vue-property-decorator'
import CourseSummary from '~/components/invoice/course-summary.vue'
import {InvoiceData, InvoiceStatus} from '~/components/invoice/invoice-data'
import InvoiceStatusSelect from '~/components/invoice/invoice-status-select.vue'
import InvoiceSummary from '~/components/invoice/invoice-summary.vue'
import OrderList from '~/components/invoice/order-list.vue'
import StatusDot from '~/components/invoice/status-dot.vue'
import {Dict, numberWithComma, tenToDot} from '~/utils/misc'
import {createRequest} from '~/utils/network-request'
import {AdminPermission} from '~/utils/permissions'

@Component({
    components: {
        InvoiceStatusSelect,
        InvoiceSummary,
        CourseSummary,
        OrderList,
        StatusDot
    },
    metaInfo() {
        return {
            title: 'Invoice ' + this.data._id
        }
    }
})
export default class Invoice extends Vue {
    static permission = AdminPermission.Invoice
    data: InvoiceData = new InvoiceData()

    InvoiceStatus = InvoiceStatus
    selectedCid = ''
    couponTitles: Dict<string> = {}

    get period() {
        const m = moment({month: this.data.month - 1, year: this.data.year})
        return m.format('MMM YYYY')
    }

    async created() {
        await this.fetch()
    }

    async fetch() {
        const res = await createRequest('/invoice-data/' + this.$route.params.id).send()
        this.data = res.data.data
        this.selectedCid = this.data.courses[0].course_id
        this.couponTitles = res.data.coupon_titles
    }

    async download() {
        const dl = this.$refs.dummyLink as unknown as HTMLAnchorElement
        dl.href = `${process.env.VUE_APP_DOMAIN_API}/invoice/${this.data._id}/download`
            + '?auth=' + await this.$auth.getAccessToken()
        dl.click()
    }

    dollar(raw: number | string) {
        return (typeof raw === 'number') ? '$' + numberWithComma(tenToDot(raw)) : raw
    }

    jumpToCourse(cid) {
        this.selectedCid = cid;
        (this.$refs.courseAnchor as Element).scrollIntoView()
    }

    async changeStatus(status: InvoiceStatus) {
        const l = this.$loading({})
        const res = await createRequest('/invoice/' + this.$route.params.id + '/status',
            'post', {}, {status}).send()
        this.data = res.data.data
        this.$message.success('Updated')
        l.close()
    }
}
