







































































import {Component} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {mixins} from 'vue-class-component'
import PaginationMixin from '~/mixins/pagination-mixin'
import moment from 'moment'
import {Dict, numberWithComma, tenToDot} from '~/utils/misc'
import {Order} from '~/components/data-class/data-class'

@Component({
    components: {}
})
export default class OrderList extends mixins(PaginationMixin) {
    step: number = 12

    tableData = []

    courseTitles: Dict<string> = {}

    async updateData() {
        const res = await createRequest(`/invoice/${this.$route.params.id}/orders`, 'get', {
            skip: (this.currentPage - 1) * this.step,
            limit: this.step
        }).send()
        this.totalCount = res.data.count
        this.tableData = res.data.orders
        this.courseTitles = res.data.course_titles
    }

    dollar(raw: number | string) {
        return (typeof raw === 'number') ? '$' + numberWithComma(tenToDot(raw)) : raw
    }

    coupon(order: Order) {
        const p = order.courses.reduce((t, oc) => t + oc.coupon_deduct, 0)
        return p ? '-' + this.dollar(p * 10) : ''
    }

    price(order: Order) {
        const p = order.courses.reduce((t, oc) => t + oc.price, 0)
        return this.dollar(p * 10)
    }

    async created() {
        await this.updateData()
    }

    date(t: number) {
        return moment(t).format('DD-MM-YYYY')
    }
}
