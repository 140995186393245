




































































































import {Component, Prop, Vue} from 'vue-property-decorator'
import {InvoiceCost, InvoiceCourse, InvoiceData, InvoiceStatus} from '~/components/invoice/invoice-data'
import {Dict, numberWithComma, tenToDot} from '~/utils/misc'
import CostItemDialog from '~/components/invoice/cost-item-dialog.vue'
import {createRequest} from '~/utils/network-request'

@Component({components: {CostItemDialog}})
export default class CourseSummary extends Vue {
    @Prop({default: () => new InvoiceData()})
    data: InvoiceData

    @Prop({default: ''})
    selectedCid: string

    @Prop()
    coupon_titles: Dict<string>

    costDialogVisible: boolean = false
    editingCost: InvoiceCost = new InvoiceCost()
    createCostItem: boolean = false

    InvoiceStatus = InvoiceStatus

    get cIds() {
        return this.data.courses.map(c => c.course_id)
    }

    get course() {
        return this.data.courses.find(c => c.course_id === this.selectedCid) || new InvoiceCourse()
    }

    rSummary() {
        return ['', '', 'Total', this.course.paid_count, this.dollar(this.course.revenue.reduce((t, r) => t + r.total, 0))]
    }

    cSummary() {
        return ['', '', 'Total', this.dollar(this.course.costs.reduce((t, c) => t + c.total, 0)), '']
    }

    dollar(raw: number | string) {
        return (typeof raw === 'number') ? '$' + numberWithComma(tenToDot(raw)) : raw
    }

    disableAction(row: InvoiceCost) {
        return row.title === 'Note Mailing & Printing'
    }

    addCostItem() {
        this.createCostItem = true
        this.editingCost = new InvoiceCost()
        this.costDialogVisible = true
    }

    editCostItem(item: InvoiceCost) {
        this.createCostItem = false
        this.editingCost = item
        this.costDialogVisible = true
    }

    removeCostItem(item: InvoiceCost) {
        console.log(item)
        this.$confirm(`Delete ${item.title}?`, 'Warning', {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'warning'
        }).then(async () => {
            // Confirm
            const index = this.course.costs.indexOf(item)
            this.course.costs.splice(index, 1)
            this.createCostItem = false
            await this.submitCost()
        }).catch(() => {
            // Cancel
        })
    }

    async submitCost() {
        if (this.createCostItem) {
            this.course.costs.push(this.editingCost)
        }
        const res = await createRequest(`/invoice/${this.data._id}/course/${this.selectedCid}/cost-items`, 'post',
            {}, {costs: this.course.costs}).send()
        this.$emit('update:data', res.data.data)
    }
}
